* {
  box-sizing: border-box;
}

body {
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input[type="date"],
input[type="time"],
input[type="button"],
input[type="submit"],
button,
textarea {
  font-family: questa-sans, -system-ui, system-ui, sans-serif;
}

a {
  color: #1877ba;
}

a:hover {
  color: #3a5ca8;
}
